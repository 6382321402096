/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useRef, useState } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { SD_Context } from "./context/sd-context";
import New_Nav from "./Components/sd-navbar/sd-new-nav";
import Footer from "./Components/sd-footer/sd-footer";
import Sd_Routes from "./sd-routes";
import UserService from "./services/sd-user.service";
import Sd_error_page from "./pages/sd-error-pages/sd-error-response";
import {
  popup1,
  popup2,
  sd_event1,
  sd_event2,
  sd_event4,
  // sd_event3,
  // sd_image1,
  // sd_image2,
  // sd_image3,
  sd_masks,
  // sd_masks,
  SD_Right_Arrow_white,

  // sd_event2,
  // sd_event3,
  // sd_event4,
} from "./Assets/imageList";
// import {useIdleTimer} from "react-idle-timer";
import ErrorBoundary from "./context/sd-error-boundary";

const App = React.memo((props) => {
  const { setUserData, display, setDisplay, refContainer } =
    useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [loading, setLoading] = React.useState(true);
  // const timeout = 900000;
  // const [remaining, setRemaining] = useState(timeout);
  const [trig, setTrig] = useState(false);
  const [trig2, setTrig2] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = useState("");
  const [shift, setShift] = useState(false);
  const refe = useRef(null);
  const refe2 = useRef(null);
  const [display2, setDisplay2] = useState(false);

  React.useEffect(async () => {
    if (
      supportedLanguages.includes(
        props.history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(props.history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }

    if (localStorage.getItem("accessToken")) {
      checkToken();
    } else {
      setLoading(false);
      setShift(false);
    }
  }, []);

  // React.useEffect(() => {
  //   if (remaining <= 0 && shift) {
  //     if (localStorage.getItem("accessToken")) {
  //       localStorage.clear();
  //       props.history?.push(`/`);
  //     }
  //   }
  // }, [remaining]);

  // React.useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //   }, 1000);
  // }, []);

  const checkToken = async () => {
    try {
      setLoading(true);
      if (localStorage.getItem("accessToken")) {
        // console.log("in access token");
        let data = await UserService.checkToken();
        if (data) {
          let result = await UserService.getUserDetails();
          setUserData(result);
          setShift(true);
          // setUserData(data);
        }
      }
    } catch (error) {
      // console.log(error);
      localStorage.clear();
    }
    setShift(true);

    setLoading(false);
  };
  // console.log("app");
  // const closeFunction = () => {
  //   setTimeout(() => {
  //     setTrig(true);
  //     try {
  //       refe.current.focus();
  //     } catch (e) {
  //       // console.log("");
  //     }
  //   }, 3000);
  //   setTimeout(() => {
  //     if (display) {
  //       setDisplay(false);
  //     }
  //   }, 10000);
  // };

  // const closeFunction2 = () => {
  //   setTimeout(() => {
  //     // setSeconds(true);
  //     setTrig2(true);
  //     try {
  //       refe2.current.focus();
  //     } catch (e) {
  //       // console.log("");
  //     }
  //   }, 3000);
  //   setTimeout(() => {
  //     if (display2) {
  //       setDisplay2(false);
  //     }
  //   }, 10000);
  // };

  // const {getRemainingTime} = useIdleTimer({
  //   timeout,
  //   crossTab: {
  //     emitOnAllTabs: true,
  //   },
  // });

  // const handleKey = (e) => {
  //   if (!e.target.className) {
  //     return;
  //   }
  //   if (e.target.className === "sd-pop-up") {
  //     if (trig) {
  //       setDisplay(false);
  //       setDisplay2(true);
  //       setTrig(false);
  //     }
  //     if (trig2) {
  //       setDisplay2(true);
  //       setTrig2(false);
  //     }
  //   }
  // };

  // const onKeyEvent = (event) => {
  //   const eventCode = event.which || event.keyCode;
  //   if (eventCode === 27) {
  //     if (trig) {
  //       setDisplay(false);
  //       setDisplay2(true);
  //       setTrig(false);
  //     }
  //     // console.log("click");

  //     if (trig2) {
  //       setDisplay2(false);
  //       setTrig2(false);
  //     }
  //   }
  // };

  return (
    <>
      {!loading && (
        <div className="sd-main" id="myDIV" name="myDIV">
          <New_Nav />
          <div ref={refContainer}></div>
          <ErrorBoundary>
            <Switch>
              <Route
                path="/:lang"
                render={(props) => {
                  if (
                    props?.history?.location?.pathname?.replaceAll?.(
                      "/",
                      ""
                    ) === props?.match?.params?.lang &&
                    supportedLanguages.includes(props?.match?.params?.lang)
                  ) {
                    return (
                      <Redirect to={`/${props?.match?.params?.lang}/home`} />
                    );
                  }
                  return supportedLanguages.includes(
                    props?.match?.params?.lang
                  ) ? (
                    <Sd_Routes {...props} />
                  ) : (
                    <Redirect
                      to={`/en-in${props?.history?.location?.pathname}`}
                    />
                  );
                }}
              />
              <Route
                path="*"
                render={(props) => {
                  // console.log("%c euhduiheruiuivheurv");
                  return (
                    <Redirect to={`/en-in${props.history.location.pathname}`} />
                  );
                }}
              />
            </Switch>
            <Footer />
          </ErrorBoundary>
          {/* {display &&
            props?.history?.location.pathname === `/${selectedLanguage}/home` ? (
            <div
              tabIndex="-1"
              ref={refe}
              autoFocus
              className={"sd-pop-up"}
              onKeyDown={(e) => onKeyEvent(e)}
              onMouseDown={(e) => {
                handleKey(e);
              }}
            >
              <div className="inside-pop">
                <img src={sd_event1
                } alt="" className="s-image" />

                {closeFunction()}
                <button
                  className={"display"}
                  onClick={() => {
                    setDisplay(false);
                    setTrig(false);
                  }}
                >
                  X
                </button>
                <div className="sd-flex-c">
                  <button
                    className="s-button"
                    onClick={() => {
                      setDisplay(false);
                      setTrig(false);
                      props.history?.push(
                        `/${selectedLanguage}/sevas-and-darshanam/paroksha-seva/laksha-kumkumarchana`
                      );
                    }}
                  >
                    More Info
                    <img src={SD_Right_Arrow_white} className="s-l-left" />
                  </button>{" "}
                  <button
                    className="s-button"
                    onClick={() => {
                      if (localStorage?.getItem("accessToken")) {
                        setDisplay(false);
                        setTrig(false);
                        props.history?.push(
                          `/${selectedLanguage}/devotee-app/online-booking/paroksha-seva/booking/laksha-kumkumarchana`
                        );
                      } else {
                        setDisplay(false);
                        setTrig(false);
                        props.history?.push(
                          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva/booking/laksha-kumkumarchana`
                        );
                      }
                    }}
                  >
                    Book now
                    <img src={SD_Right_Arrow_white} className="s-l-left" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          {/*  */}
        </div>
      )}
      <div className="sd-error">
        <Sd_error_page />
      </div>
    </>
  );
});
App.displayName = "App";

export default withRouter(App);
