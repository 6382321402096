import React from "react";
import {withRouter} from "react-router-dom";
import {
  SD_accomadation,
  sd_acc_white,
  SD_annadanam,
  SD_arrow_right_orange,
  sd_e_hundi_icon,
  sd_hands,
  SD_parokshaseva,
  SD_Right_Arrow_Blue,
  SD_Right_Arrow_white,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateTwo = ({history}) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-two">
      <div className="sd-box-one sd-first-color">
        <div className="sd-box-one-image">
          <img
            src={sd_e_hundi_icon}
            alt="Accomadation"
            className="sd-logo-img sd-size-one"
          />
        </div>
        <div className="sd-box-one-content">
          <h2> e-Hundi </h2>
          <p>
            e-Hundi allows donations from people across the globe for the
            welfare of the sacred Srisailam. Devotees can offer their donations
            via internet banking.
          </p>
          <h6
            onClick={() => {
              history?.push(`/${selectedlanguage}/donations/e-hundi`);
            }}
          >
            More Info <img src={SD_Right_Arrow_Blue} alt=">" />
          </h6>
         
        </div>
      </div>

      <div className="sd-box-one sd-second-color">
        <div className="sd-box-one-image">
          <img
            src={sd_hands}
            alt="SD_parokshaseva"
            className="sd-logo-img sd-size-four"
          />
        </div>
        <div className="sd-box-one-content">
          <h2>Paroksha Seva</h2>
          <p>
            In a world, that's filled with the essence of Shiva, one can now
            wholeheartedly worship Sri Mallikarjuna Swamy and Bhramarambika Devi
            from anywhere in the world.
          </p>
          <h6
            onClick={() => {
              history?.push(
                `/${selectedlanguage}/sevas-and-darshanam/paroksha-seva`
              );
            }}
          >
            More Info <img src={SD_Right_Arrow_Blue} alt=">" />
          </h6>
          
        </div>
      </div>

      <div className="sd-box-one sd-third-color">
        <div className="sd-box-one-image">
          <img
            src={SD_annadanam}
            alt="SD_annadanam"
            className="sd-logo-img sd-size-three"
          />
        </div>
        <div className="sd-box-one-content">
          <h2 className="sd-c-white">AnnaPrasadam</h2>
          <p className="sd-c-white">
            Offering one Annadhanam is equals to donating 1000 elephants, a
            crore cows, gold and land that extends until seashore; fulfilling
            all duties of a family.
          </p>
          <h6
            className="c-white"
            onClick={() => {
              history?.push(
                `/${selectedlanguage}/donations/sbms-annaprasadam-trust`
              );
            }}
          >
            More Info <img src={SD_Right_Arrow_white} alt=">" />
          </h6>
     
        </div>
      </div>

      <div className="sd-box-one sd-fourth-color">
        <div className="sd-box-one-image">
          <img
            src={sd_acc_white}
            alt="accomadation"
            className="sd-logo-img sd-size-one"
          />
        </div>
        <div className="sd-box-one-content">
          <h2 className="sd-c-white">Accommodation</h2>
          <p className="sd-c-white">
            Comfortable and hygienic AC/Non AC Rooms, Cottages, Guest Houses,
            Hotels with all amenities to accommodate pilgrims on holy Srisailam
            Hills.
          </p>
          <h6
            className="c-white"
            onClick={() => {
              if (localStorage?.getItem("accessToken")) {
                history?.push(
                  `/${selectedlanguage}/devotee-app/online-booking/accommodations`
                );
              } else {
                history?.push(
                  `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
                );
              }
            }}
          >
            More Info <img src={SD_Right_Arrow_white} alt=">" />
          </h6>
      
        </div>
      </div>
    </div>
  );
};

export default withRouter(TemplateTwo);
