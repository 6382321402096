import React, {useState, useEffect} from "react";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import "../../../Utils/sd-styles/sd-css/animate.min.css";
import Slider from "react-slick";

import {
  sd_home_banner_2,
  sd_home_banner_3,
  sd_home_banner_4,
  sd_home_banner_5,
  sd_home_banner_1,
  sd_home_banner_6,
  SD_Right_Arrow_white,
  sd_srisaila_logo,
} from "../../../Assets/imageList";
import {withRouter} from "react-router";

const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateOne = ({history}) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const [loading, setLoading] = useState(true);
  const [scroll, setScroll] = useState("5");
  //-------------------------------------------------------------states--------------------------------------------------------//

  //------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(() => {
    setLoading(true);
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  //-------------------------------------------------------------consts--------------------------------------------------------//

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    nextArrow: <div />,
    prevArrow: <div />,
    autoplaySpeed: 15000,
  };
  const styles = {
    slide: {
      position: "absolute",
      width: "100vw",
      height: "100vh",
      zIndex: "3",
      backgroundColor: "white",
      display: "flex",
      top: "0",
    },
    svg: {
      margin: "auto",
      background: "white none repeat scroll 0% 0%",
      display: "block",
      shapeRendering: "auto",
    },
  };
  //-----------------------------------------------------------consts--------------------------------------------------------//

  return (
    <div className="sd-template-one">
      {loading && (
        <div style={styles.slide}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={styles.svg}
            width="120px"
            height="120px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <image
              overflow="visible"
              width="85"
              xlinkHref={sd_srisaila_logo}
              transform="matrix(0.6242 0 0 0.6242 25 18.1847)"
            ></image>
            <path
              d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
              fill="#f58220"
              stroke="none"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                dur="1s"
                repeatCount="indefinite"
                keyTimes="0;1"
                values="0 50 51;360 50 51"
              ></animateTransform>
            </path>
          </svg>
        </div>
      )}
      <div className="sd-t1-slider">
        <Slider {...settings}>
          <div className="item">
            <img src={sd_home_banner_1} alt="banner1" width="100%" />
          </div>
          <div className="item">
            <img
              src={sd_home_banner_6}
              alt="banner"
              width="100%"
              onLoad={() => {
                setLoading(false);
              }}
            />
          </div>

          {/* <div className="item">
            <img src={sd_home_banner_spcl} alt="banner2" width="100%" />
          </div> */}
          <div className="item">
            <img src={sd_home_banner_2} alt="banner3" width="100%" />
          </div>
          <div className="item">
            <img src={sd_home_banner_3} alt="banner4" width="100%" />
          </div>
          <div className="item">
            <img src={sd_home_banner_4} alt="banner5" width="100%" />
          </div>
          <div className="item">
            <img src={sd_home_banner_5} alt="banner6" width="100%" />
          </div>
        </Slider>
      </div>
      <div className="sd-t1-bottom">
        <p className="sd-orange">Temple News</p>
        <marquee
          behavior="scroll"
          id="marquee"
          direction="left"
          className="sd-marquee-text"
          scrollamount={scroll}
          onMouseOver={() => {
            document.getElementById("marquee").stop();
          }}
          onMouseOut={() => {
            document.getElementById("marquee").start();
          }}
        >
          <a
            className="ml-3 mr-3 under"
            // onClick={() => {
            //   history?.push(
            //     `/${selectedLanguage}/sevas-and-darshanam/paroksha-seva`
            //   );
            // }}
          >
            Paroksha Seva{" "}
          </a>{" "}
          with godly blessings, scroll down to know all about Srisailam. You can
          also book Paroksha Seva at
          <a
            className="ml-3 mr-3 under"
            style={{textTransform: "none"}}
            onClick={() => {
              window.open("http://aptemples.ap.gov.in", "_blank");
            }}
          >
            https://aptemples.ap.gov.in
          </a>
          . Online Booking Procedure :
          <a
            className="ml-3 mr-3 under"
            // onClick={() => {
            //   window.open(
            //     "https://tms.ap.gov.in/resources/parokshasevabooking.pdf",
            //     "_blank"
            //   );
            // }}
          >
            Paroksha seva
          </a>
          |{" "}
          <a
            className="ml-3 mr-3 under"
            // onClick={() => {
            //   window.open(
            //     "https://tms.ap.gov.in/resources/ehundipayment.pdf",
            //     "_blank"
            //   );
            // }}
          >
            e-Hundi
          </a>
          The Holy month Karthika Masam starts from 26th of October and ends by
          23rd of November. | In Karthika Masam, due to heavy flow of devotees,
          Sparsa Darshanam will be halted on specific dates : 29th, 30th, 31st
          of October; 4th, 5th, 6th, 7th, 8th, 12th, 13th, 14th, 19th, 20th,
          21st, 22nd of November. | As 25-10-2022 is Surya Grahanam, the temple
          gates will be closed from 06:30 a.m to 06:30 p.m, Pratyaksha Sevas &
          Paroksha Sevas will be halted. | As this Karthika Pournami
          (08-11-2022) is also Chandra Grahanam, the temple gates will be closed
          from 06:30 a.m to 06:30 p.m, Pratyaksha Sevas & Paroksha Sevas will be
          halted.|
        </marquee>
        <div>
          <button
            // onClick={() => {
            //   history?.push(
            //     `/${selectedLanguage}/media-room/press/temple-news`
            //   );
            // }}
          >
            View All <img src={SD_Right_Arrow_white} alt=">" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default withRouter(TemplateOne);
